import { type ComponentProps } from 'react';
import {
  Select as AriaSelect,
  Button,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  SelectValue,
} from 'react-aria-components';
import { ArrowDown } from '@/components/icons';
import { cn } from '@/helpers';
type Item = { name: string; id: string; description?: string };

const Select = (
  props: ComponentProps<typeof ListBox<Item>> &
    ComponentProps<typeof AriaSelect> & {
      isRequired?: boolean;
      selectedKey: string;
      onSelect: (id: string) => void;
      'aria-errormessage'?: string;
    }
) => (
  // Transform Set to simple string
  <AriaSelect
    className={cn('relative flex flex-col gap-y-2 text-sm', props.className)}
    selectedKey={props?.selectedKey}
    onSelectionChange={(id) => {
      props.onSelect(id as string);
    }}>
    {props['aria-label'] && (
      <Label className="font-medium">
        {props['aria-label']} {props.isRequired && <span className="text-red-500">*</span>}
      </Label>
    )}
    <Button
      className={cn(
        'flex justify-between w-full gap-x-2 rounded-lg border border-grey2 bg-white px-3 py-[10px] text-black',
        {
          'border-red-500': props?.['aria-errormessage'],
        }
      )}>
      <SelectValue className="text-black" />
      <ArrowDown className="size-4 self-end text-black" />
    </Button>
    {props?.['aria-errormessage'] ? (
      <div className="absolute top-[96%] text-xxs text-red-500">{props?.['aria-errormessage']}</div>
    ) : props?.['aria-details'] ? (
      <div className="flex items-center gap-2 text-sm text-ghost">
        <span className="flex size-3 shrink-0 items-center justify-center rounded-full border border-ghost text-xxs">
          i
        </span>
        {props?.['aria-details']}
      </div>
    ) : null}
    <Popover>
      <ListBox
        {...props}
        defaultSelectedKeys={new Set([props.selectedKey])}
        selectedKeys={new Set([props.selectedKey])}
        selectionMode={'single'}
        className="flex max-h-[300px] flex-col gap-y-4 overflow-hidden overflow-y-scroll rounded-lg border border-solid border-grey3 bg-white shadow-sm">
        {(item: Item) => (
          <ListBoxItem
            {...item}
            aria-label={item.name}
            className={() =>
              'text-base flex flex-col font-normal text-black outline-none cursor-pointer w-full p-2 focus:bg-slate-300 selected:bg-primary selected:text-white'
            }>
            <p className="text-xs">{item.name}</p>
            {item?.description && (
              <p className={cn('text-xxs selected:text-white')}>{item.description}</p>
            )}
          </ListBoxItem>
        )}
      </ListBox>
    </Popover>
  </AriaSelect>
);
export default Select;
