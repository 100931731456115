import { useEffect, useMemo, useState } from 'react';
import { CircleMarker } from './CircleMarker';
import { Line } from './Line';
import { Polygon } from './Polygon';
import { useGetAzimuth } from './useGetAzimuth';

/** User should select the ridge line of the roof
 * We compute the length and large of the roof
 **/
export const RidgePoints = ({
  roofPoints,
  onCalculateOrientation,
}: {
  roofPoints: google.maps.LatLngLiteral[];
  onCalculateOrientation?: ({
    azimuth,
    azimuthCardinal,
  }: {
    azimuth: number;
    azimuthCardinal: string;
  }) => void;
}) => {
  const [showRidgeLine, setShowRidgeLine] = useState(false);
  const [ridgeLinePoints, setRidgeLinePoints] = useState<google.maps.LatLngLiteral[]>([]);
  const getAzimut = useGetAzimuth();

  const ridgePoints = useMemo(() => {
    if (roofPoints.length === 4) {
      const middle1 = {
        lat: (roofPoints[0]?.lat + roofPoints[1]?.lat) / 2,
        lng: (roofPoints[0]?.lng + roofPoints[1]?.lng) / 2,
      };
      const middle2 = {
        lat: (roofPoints[1]?.lat + roofPoints[2]?.lat) / 2,
        lng: (roofPoints[1]?.lng + roofPoints[2]?.lng) / 2,
      };
      const middle3 = {
        lat: (roofPoints[2]?.lat + roofPoints[3]?.lat) / 2,
        lng: (roofPoints[2]?.lng + roofPoints[3]?.lng) / 2,
      };
      const middle4 = {
        lat: (roofPoints[3]?.lat + roofPoints[0]?.lat) / 2,
        lng: (roofPoints[3]?.lng + roofPoints[0]?.lng) / 2,
      };
      return [middle1, middle2, middle3, middle4];
    }
  }, [roofPoints]);

  useEffect(() => {
    const azimuth = getAzimut(ridgeLinePoints);
    azimuth && onCalculateOrientation?.(azimuth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ridgeLinePoints]);

  return (
    <>
      <Polygon path={roofPoints} />

      {ridgePoints?.[0] && (
        <CircleMarker
          center={ridgePoints[0]}
          onClick={() => {
            setShowRidgeLine(true);
            setRidgeLinePoints([roofPoints[1], roofPoints[0]]);
          }}
        />
      )}
      {ridgePoints?.[1] && (
        <CircleMarker
          center={ridgePoints[1]}
          onClick={() => {
            setShowRidgeLine(true);
            setRidgeLinePoints([roofPoints[2], roofPoints[1]]);
          }}
        />
      )}
      {ridgePoints?.[2] && (
        <CircleMarker
          center={ridgePoints[2]}
          onClick={() => {
            setShowRidgeLine(true);
            setRidgeLinePoints([roofPoints[3], roofPoints[2]]);
          }}
        />
      )}
      {ridgePoints?.[3] && (
        <CircleMarker
          center={ridgePoints[3]}
          onClick={() => {
            setShowRidgeLine(true);
            setRidgeLinePoints([roofPoints[0], roofPoints[3]]);
          }}
        />
      )}
      {showRidgeLine && ridgePoints && <Line path={ridgeLinePoints} />}
    </>
  );
};
