import { GoogleMapsContext } from '@vis.gl/react-google-maps';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { rotatePath } from './rotatePath';

export function Rectangle(props: {
  height: number;
  width: number;
  center?: google.maps.LatLngLiteral;
  angle?: number;
  onPathChange?: (path: google.maps.LatLngLiteral[]) => void;
}) {
  const map = useContext(GoogleMapsContext)?.map;
  const polygon = useRef(
    new google.maps.Polygon({
      fillColor: '#fff',
      strokeWeight: 2,
      fillOpacity: 0.2,
      strokeColor: '#00a6d6',
      strokeOpacity: 1,
      clickable: false,
    })
  );

  const pointsFromProps = useMemo(() => {
    if (props.center && props.height && props.width) {
      return [
        { lat: props.center.lat - props.height / 2, lng: props.center.lng - props.width / 2 },
        { lat: props.center.lat - props.height / 2, lng: props.center.lng + props.width / 2 },
        { lat: props.center.lat + props.height / 2, lng: props.center.lng + props.width / 2 },
        { lat: props.center.lat + props.height / 2, lng: props.center.lng - props.width / 2 },
      ];
    } else {
      return [];
    }
  }, [props.center, props.height, props.width]);

  useEffect(() => {
    polygon.current.setMap(map!);
    const newPath = rotatePath(pointsFromProps, props.angle ?? 0, props.center!, map);
    polygon.current.setPath(newPath);
    props?.onPathChange?.(newPath);
  }, [map, pointsFromProps, props.angle]);

  return null;
}
