/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/unbound-method */
import { Map as GoogleMap } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';
import { RoofPolygon } from './RoofPolygon';
import calculatePanelNumber from './calculatePanelNumber';

const RoofMap = ({
  center,
  onChange,
}: {
  center?: google.maps.LatLngLiteral;
  onChange?: ({
    points,
    panelNbr,
    colNbr,
    lineNbr,
    area,
  }: {
    points: google.maps.LatLngLiteral[];
    panelNbr: number;
    colNbr: number;
    lineNbr: number;
    area: number;
  }) => void;
}) => {
  const [data, setData] = useState<{ points: google.maps.LatLngLiteral[]; area: number }>({
    points: [],
    area: 0,
  });

  useEffect(() => {
    const { panelNumber, colNbr, lineNbr } = calculatePanelNumber({
      roofPoints: data.points,
      roofAngle: 45,
      panel: { height: 1.74, width: 1.15 },
    });
    onChange?.({
      points: data.points,
      panelNbr: panelNumber,
      colNbr,
      lineNbr,
      area: data.area,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.points]);

  return center ? (
    <>
      <GoogleMap
        key={`${center.lat}-${center.lng}`}
        className="size-full min-h-96 rounded-xl"
        defaultCenter={center}
        defaultZoom={20}
        disableDefaultUI={true}
        mapTypeId={'hybrid'}
        styles={[
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
        ]}
        mapTypeControl={false}
        keyboardShortcuts={false}
        tilt={0}
        zoomControl={true}
        scrollwheel={false}
        disableDoubleClickZoom={true}
        fullscreenControl={false}>
        <RoofPolygon
          defaultCenter={center}
          onChange={({ points, area }) => {
            setData({ points, area });
          }}
        />
      </GoogleMap>
    </>
  ) : null;
};

export default RoofMap;
