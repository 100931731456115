import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect, useMemo, useState } from 'react';
import { CircleMarker } from './CircleMarker';
import { Polygon } from './Polygon';

/**
 * Render editable polygon on the map to define the roof area
 */
export const RoofPolygon = ({
  defaultCenter,
  onChange,
}: {
  defaultCenter?: google.maps.LatLngLiteral;
  onChange?: ({ points, area }: { points: google.maps.LatLngLiteral[]; area: number }) => void;
}) => {
  const geometry = useMapsLibrary('geometry');
  const [point1, setPoint1] = useState<google.maps.LatLngLiteral | null>(
    defaultCenter ? { lat: defaultCenter.lat - 0.00005, lng: defaultCenter.lng - 0.0001 } : null
  );
  const [point2, setPoint2] = useState<google.maps.LatLngLiteral | null>(
    defaultCenter ? { lat: defaultCenter.lat - 0.00005, lng: defaultCenter.lng + 0.0001 } : null
  );
  const [point3, setPoint3] = useState<google.maps.LatLngLiteral | null>(
    defaultCenter ? { lat: defaultCenter.lat + 0.00005, lng: defaultCenter.lng + 0.0001 } : null
  );
  const [point4, setPoint4] = useState<google.maps.LatLngLiteral | null>(
    defaultCenter ? { lat: defaultCenter.lat + 0.00005, lng: defaultCenter.lng - 0.0001 } : null
  );

  useEffect(() => {
    if (!defaultCenter) {
      return;
    }
    const { lat, lng } = defaultCenter;
    setPoint1({ lat: lat - 0.00005, lng: lng - 0.0001 });
    setPoint2({ lat: lat - 0.00005, lng: lng + 0.0001 });
    setPoint3({ lat: lat + 0.00005, lng: lng + 0.0001 });
    setPoint4({ lat: lat + 0.00005, lng: lng - 0.0001 });
  }, [defaultCenter]);

  const pointsArray = useMemo(
    () => [point1, point2, point3, point4].filter((p) => p) as google.maps.LatLngLiteral[],
    [point1, point2, point3, point4]
  );

  useEffect(() => {
    if (!geometry) {
      return;
    }
    const area = geometry?.spherical.computeArea(pointsArray);
    onChange?.({ points: pointsArray, area });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointsArray, geometry]);

  return (
    <>
      <Polygon path={pointsArray} />

      {point1 && <CircleMarker draggable center={point1} onDrag={setPoint1} />}
      {point2 && <CircleMarker draggable center={point2} onDrag={setPoint2} />}
      {point3 && <CircleMarker draggable center={point3} onDrag={setPoint3} />}
      {point4 && <CircleMarker draggable center={point4} onDrag={setPoint4} />}
    </>
  );
};
