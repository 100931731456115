import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { type LeasingContract, type Project } from '@/apis';
import { solvencyUrl } from '@/apis/location';
import { useUpdateProjectLeasingContract } from '@/apis/simulation/hooks';
import { ArrowRight, LoaderCircle } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button, InfoCard, Select } from '@/components/ui';
import { useLocalProject } from '@/services/project';

type Step5Form = {
  bankSeniority: NonNullable<Project['leasingContract']['bankSeniority']>;
  ownershipSeniority: NonNullable<Project['leasingContract']['ownershipSeniority']>;
  workSeniority: NonNullable<Project['leasingContract']['workSeniority']>;
};

const getDefaultValues = (data?: Project['leasingContract']) => ({
  bankSeniority: data?.bankSeniority ?? 0,
  ownershipSeniority: data?.ownershipSeniority ?? 0,
  workSeniority: data?.workSeniority ?? 0,
});

const Seniority = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { data, mutate } = useLocalProject();
  const defaultValues = getDefaultValues(data?.leasingContract);
  const { mutateAsync: updateLeasingContract } = useUpdateProjectLeasingContract();

  const { watch, setValue, formState, handleSubmit, register } = useForm<Step5Form>({
    mode: 'onTouched',
    defaultValues,
    values: defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        bankSeniority: yup.number().required(t('required')).min(1, t('min-0')),
        ownershipSeniority: yup.number().required(t('required')).min(1, t('min-0')),
        workSeniority: yup.number().required(t('required')).min(1, t('min-0')),
      })
    ),
  });

  const onSubmit = async (formData: Step5Form) => {
    setIsLoading(true);
    await updateLeasingContract({
      projectId: data?._id ?? '',
      data: {
        leasingContract: {
          bankSeniority: formData.bankSeniority,
          ownershipSeniority: formData.ownershipSeniority,
          workSeniority: formData.workSeniority,
        },
      },
    });
    mutate({
      leasingContract: {
        ...data?.leasingContract,
        bankSeniority: formData.bankSeniority,
        ownershipSeniority: formData.ownershipSeniority,
        workSeniority: formData.workSeniority,
      } as LeasingContract,
    });
    window.location = solvencyUrl(data?._id ?? '') as unknown as Location;
  };

  return (
    <AnimatedPage>
      <h1 className="text-xl font-semibold text-black">{t('finalize-your-folder')}</h1>
      <p className="text-sm text-ghost">{t('finalize-your-folder-subtitle')}</p>
      <div className="flex flex-col flex-wrap gap-4 lg:flex-row">
        <Select
          {...register('bankSeniority')}
          className="flex-1 shrink-0"
          isRequired={true}
          aria-label={t('bank-seniority')}
          items={Array.from({ length: 30 }, (_, i) => ({
            name: t('year-count', { count: i + 1 }),
            id: String(i + 1),
          }))}
          selectedKey={watch('bankSeniority')?.toString()}
          onSelect={(id) => {
            setValue('bankSeniority', Number(id), {
              shouldValidate: true,
            });
          }}
          aria-errormessage={formState.errors.bankSeniority?.message}
          aria-details={t('bank-seniority-details')}
        />
        <Select
          {...register('ownershipSeniority')}
          className="flex-1 shrink-0"
          isRequired={true}
          aria-label={t('ownership-seniority')}
          items={Array.from({ length: 30 }, (_, i) => ({
            name: t('year-count', { count: i + 1 }),
            id: String(i + 1),
          }))}
          selectedKey={watch('ownershipSeniority')?.toString()}
          onSelect={(id) => {
            setValue('ownershipSeniority', Number(id), {
              shouldValidate: true,
            });
          }}
          aria-errormessage={formState.errors.ownershipSeniority?.message}
          aria-details={t('ownership-seniority-details')}
        />
      </div>
      <Select
        {...register('workSeniority')}
        className="lg:self-start"
        isRequired={true}
        aria-label={t('work-seniority')}
        items={Array.from({ length: 30 }, (_, i) => ({
          name: t('year-count', { count: i + 1 }),
          id: String(i + 1),
        }))}
        selectedKey={watch('workSeniority')?.toString()}
        onSelect={(id) => {
          setValue('workSeniority', Number(id), {
            shouldValidate: true,
          });
        }}
        aria-errormessage={formState.errors.workSeniority?.message}
        aria-details={t('work-seniority-details')}
      />

      <InfoCard level="warning" border={false}>
        <p className="text-sm font-medium text-black">{t('finalizing-accept-policy')}</p>
      </InfoCard>

      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={() => {
            navigate('/location/step-4');
          }}
        />
        <Button
          label={t('next-step')}
          className={'mt-4 bg-primary text-white'}
          isDisabled={!formState?.isValid}
          onPress={() => {
            void handleSubmit(onSubmit)();
          }}
          PostIcon={(props) =>
            isLoading ? <LoaderCircle {...props} /> : <ArrowRight {...props} />
          }
        />
      </div>
    </AnimatedPage>
  );
};
export default Seniority;
