import { useEffect, useMemo, useState } from 'react';
import Button from '../Button/Button';
import { Arrow } from './Arrow';
import { CircleMarker } from './CircleMarker';
import { Rectangle } from './Rectangle';
import { useGetAzimuth } from './useGetAzimuth';
import { RotateLeft, RotateRight } from '@/components/icons';

/**
 * Render the chosen shelter with proper size and orientation
 * @returns
 */
export const SolarShelter = ({
  defaultCenter,
  height,
  width,
  onPointChange,
  onAzimuthChange,
}: {
  defaultCenter: google.maps.LatLngLiteral;
  height?: number;
  width?: number;
  onPointChange?: (center: google.maps.LatLngLiteral) => void;
  onAzimuthChange?: (azimuth: { azimuth: number; azimuthCardinal: string }) => void;
}) => {
  const getAzimut = useGetAzimuth();
  const [center, setCenter] = useState<google.maps.LatLngLiteral | null>(defaultCenter ?? null);
  const [angle, setAngle] = useState<number>(0);
  const [path, setPath] = useState<google.maps.LatLngLiteral[]>([]);
  useEffect(() => {
    if (center) {
      onPointChange?.(center);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);
  // Convert height from meters to degrees
  const h = height ? height / 111111 : 0;
  // Convert width from meters to degrees
  const w = width ? width / 111111 : 0;
  // Draw a rectangle with height and width. This rectangle is draggable and rotateable
  const arrowPath = useMemo(
    () => [
      // Middle of longest edge
      {
        lat: path?.[1]?.lat + (path?.[2]?.lat - path?.[1]?.lat) / 2,
        lng: path?.[1]?.lng + (path?.[2]?.lng - path?.[1]?.lng) / 2,
      },
      { lat: center?.lat ?? 0, lng: center?.lng ?? 0 },
    ],
    [path, center?.lat, center?.lng]
  );

  useEffect(() => {
    if (path.length < 3) {
      return;
    }
    const azimuth = getAzimut([path[2], path[1]]);
    azimuth && onAzimuthChange?.(azimuth);
  }, [path]);

  return (
    <>
      <div className="absolute right-1 top-1 flex items-center gap-x-2">
        <Button
          className="bg-primary text-white"
          label={''}
          PreIcon={RotateLeft}
          onPress={() => {
            setAngle((prev) => prev - 3);
          }}
        />
        <Button
          className="bg-primary text-white"
          label={''}
          PreIcon={RotateRight}
          onPress={() => {
            setAngle((prev) => prev + 3);
          }}
        />
      </div>

      {center && <Arrow path={arrowPath} />}
      {center && (
        <CircleMarker
          center={center}
          draggable
          onDrag={setCenter}
          options={{
            fillColor: 'transparent',
            fillOpacity: 0,
            strokeColor: 'transparent',
            radius: 16,
          }}
        />
      )}
      {/**
       * Draw arrow between the middles of greater sides of the rectangle
       * apply angle
       */}

      {center && height && width && (
        <Rectangle angle={angle} width={w} height={h} center={center} onPathChange={setPath} />
      )}
    </>
  );
};
