import { rotatePoint } from './rotatePoint';

export function rotatePath(
  points: google.maps.LatLngLiteral[],
  angle: number,
  center: google.maps.LatLngLiteral,
  map?: google.maps.Map | null
) {
  if (angle === 0) return points;
  const prj = map?.getProjection();
  // Get the center of the polygon
  const origin = prj?.fromLatLngToPoint(center) ?? { x: 0, y: 0 };

  const coords = points.map((latLng) => {
    const point = prj?.fromLatLngToPoint(latLng) ?? { x: 0, y: 0 };
    const rotatedLatLng = prj?.fromPointToLatLng(
      rotatePoint(point, origin, angle) as google.maps.Point
    );
    return { lat: rotatedLatLng?.lat() ?? 0, lng: rotatedLatLng?.lng() ?? 0 };
  });

  return coords;
}
