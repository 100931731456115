import { yupResolver } from '@hookform/resolvers/yup';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { type InstallationData } from '@/apis';
import { Aim, ArrowRight, LoaderCircle } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { AddressField, Button } from '@/components/ui';
import { type Simulation, useLocalProject } from '@/services/project';

export type AddressForm = {
  address: Omit<Simulation['address'], 'travelFromSoleriel'>;
};

const useGeocode = (pos: google.maps.LatLngLiteral, cb: (placeId: string) => void) => {
  const geocode = useMapsLibrary('geocoding');
  useEffect(() => {
    if (!geocode) {
      return;
    }
    void new geocode.Geocoder().geocode({ location: pos }, (results, status) => {
      if (status === 'OK') {
        const placeId = results?.[0].place_id;
        placeId && cb(placeId);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geocode, pos]);
};

const useAddressDetailed = (placeId: string) => {
  const places = useMapsLibrary('places');
  const [address, setAddress] = useState<google.maps.places.PlaceResult>();
  useEffect(() => {
    const dumbDiv = document.createElement('div');
    if (!places || !placeId) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
    void new places.PlacesService(dumbDiv).getDetails({ placeId }, (place, status) => {
      if (status === 'OK' && place) {
        setAddress(place);
      }
    });
  }, [places, placeId]);
  return address;
};

/**
 * Return the current position and a function to get the current position
 */
const useGeoLocalize = () => {
  const [loading, setLoading] = useState(false);
  const [pos, setPos] = useState<google.maps.LatLngLiteral>();
  const geoLocalize = useCallback(() => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setLoading(false);
          setPos({ lat, lng });
        },
        (error) => {
          setLoading(false);
          console.error(error);
        },
        { maximumAge: 60000, timeout: 10000, enableHighAccuracy: true }
      );
    } else {
      // Fallback for no geolocation
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  return { pos, geoLocalize, loading };
};

const getDefaultValues = (data?: InstallationData) => ({
  address: data?.address ?? {
    placeId: '',
    description: '',
    latitude: 0,
    longitude: 0,
    postalCode: '',
    city: '',
    countryCode: '',
    number: '',
    street: '',
  },
});

const Address = ({ nextRoute, previousRoute }: { nextRoute: string; previousRoute: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateInstallationData, installationData } = useLocalProject();
  const defaultValues = getDefaultValues(installationData);
  const [placeId, setPlaceId] = useState<string | undefined>();
  const { pos: defaultCenter, geoLocalize, loading } = useGeoLocalize();
  useGeocode(defaultCenter!, setPlaceId);

  const { setValue, watch, formState, handleSubmit } = useForm<AddressForm>({
    mode: 'all',
    defaultValues,
    values: defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        address: yup.object().shape({
          description: yup.string().required(),
          placeId: yup.string().required(t('address-must-exists')),
          countryCode: yup.string().required(t('invalid-address')),
          postalCode: yup.string().required(t('address-must-have-postal-code')),
          city: yup.string().required(t('address-must-have-city')),
          number: yup.string(),
          street: yup.string().required(t('address-must-have-street')),
          latitude: yup.number().required(),
          longitude: yup.number().required(),
        }),
      })
    ),
  });

  const onSubmit = (data: AddressForm) => {
    mutateInstallationData({ address: data.address as Simulation['address'] });
    navigate(nextRoute);
  };

  const address = useAddressDetailed(placeId!);

  useEffect(() => {
    if (address) {
      setValue(
        'address',
        {
          description: address?.formatted_address ?? '',
          placeId: address.place_id ?? '',

          postalCode:
            address?.address_components?.find((c) => c.types.includes('postal_code'))?.long_name ??
            '',
          city:
            address?.address_components?.find((c) => c.types.includes('locality'))?.long_name ?? '',
          street:
            address?.address_components?.find((c) => c.types.includes('route'))?.long_name ?? '',
          number:
            address?.address_components?.find((c) => c.types.includes('street_number'))
              ?.long_name ?? '',
          countryCode:
            address?.address_components?.find((c) => c.types.includes('country'))?.short_name ?? '',
          latitude: address?.geometry?.location?.lat() ?? 0,
          longitude: address?.geometry?.location?.lng() ?? 0,
        },
        { shouldValidate: true, shouldTouch: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  return (
    <AnimatedPage>
      <h1 className="text-lg font-semibold text-black">{t('address-title')}</h1>
      <Button
        label={t('localize-myself')}
        className={'mt-4 w-full justify-center bg-black text-white'}
        onPress={() => {
          geoLocalize();
        }}
        PostIcon={(props) => (loading ? <LoaderCircle {...props} /> : <Aim {...props} />)}
      />
      <AddressField
        placeholder={t('i-type-my-address')}
        defaultValue={
          {
            place_id: watch('address')?.placeId,
            description: watch('address')?.description,
          } as google.maps.places.AutocompletePrediction
        }
        onSelect={(address) => {
          setPlaceId(address?.place_id);
        }}
        errorMessage={Object.values(formState.errors.address ?? {})
          .map((e: unknown) => (e as { message: string }).message)
          .join(', ')}
      />
      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={() => {
            navigate(previousRoute);
          }}
        />
        <Button
          label={t('next-step')}
          className={'mt-4 self-end bg-primary text-white'}
          onPress={() => {
            void handleSubmit(onSubmit)();
          }}
          PostIcon={(props) => <ArrowRight {...props} />}
        />
      </div>
    </AnimatedPage>
  );
};
export default Address;
