import { motion } from 'framer-motion';
import { useEffect, useState, type PropsWithChildren } from 'react';
import { Modal as AriaModal, ModalOverlay } from 'react-aria-components';

const showSnackbar = (message: string) => {
  document.dispatchEvent(
    new CustomEvent<{ message: string }>('snackbar', {
      detail: {
        message,
      },
    })
  );
};

const hideSnackbar = () => {
  document.dispatchEvent(new CustomEvent('snackbar', { detail: { message: '' } }));
};

export const SnackbarWrapper = ({ children }: PropsWithChildren) => {
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarVisible, setSnackbarVisible] = useState<boolean>(false);

  useEffect(() => {
    if (snackbarMessage) {
      setSnackbarVisible(true);
      setTimeout(() => {
        setSnackbarVisible(false);
      }, 3000);
    }
  }, [snackbarMessage]);

  const handleEvent = (event: Event) => {
    const customEvent = event as CustomEvent;
    setSnackbarMessage(customEvent.detail.message as string);
  };

  useEffect(() => {
    document.addEventListener('snackbar', handleEvent as EventListener);
    // Remove event listener on unmount
    return () => {
      document.removeEventListener('snackbar', handleEvent as EventListener);
    };
  }, []);

  return (
    <>
      {children}
      <ModalOverlay
        isOpen={snackbarVisible}
        className={({ isEntering, isExiting }) => `
         fixed inset-0 z-10 overflow-y-auto bg-black/30 flex min-h-full items-start justify-end text-center backdrop-blur-sm
        ${isEntering ? 'animate-in fade-in duration-300 ease-out' : ''}
        ${isExiting ? 'animate-out fade-out duration-200 ease-in' : ''}
        `}>
        <AriaModal
          isOpen={snackbarVisible}
          onOpenChange={(isOpen) => {
            !isOpen && setSnackbarVisible(false);
          }}>
          <motion.div
            className="relative flex max-w-2xl flex-col gap-4 overflow-hidden rounded-bl-md bg-white p-4 outline-none"
            initial={{ x: '10vw', opacity: 0, filter: 'blur(5px)' }}
            animate={{ x: '0vw', opacity: 1, filter: 'blur(0px)' }}
            exit={{ x: '-10vw', opacity: 0, filter: 'blur(5px)' }}
            transition={{
              type: 'spring',
              stiffness: 200,
              damping: 26,
              duration: 0.1,
            }}>
            <>
              <p className="text-left text-sm font-bold text-primary">{snackbarMessage ?? ''}</p>
              <div className="absolute bottom-0 left-0 h-1 w-full bg-grey3">
                <motion.div
                  className="absolute left-0 top-0 h-full rounded-full bg-primary"
                  initial={{ width: '100%' }}
                  animate={{ width: '0%' }}
                  transition={{ duration: 3, ease: 'linear' }}
                />
              </div>
            </>
          </motion.div>
        </AriaModal>
      </ModalOverlay>
    </>
  );
};

export const useSnackbar = () => ({
  showSnackbar,
  hideSnackbar,
});
