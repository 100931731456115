import { type SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none" {...props}>
    <path
      fill="currentColor"
      d="m7 7.684 2.887-2.887.825.825L7 9.334 3.288 5.622l.824-.825L7 7.684Z"
    />
  </svg>
);
export default SvgComponent;
