export function rotatePoint(
  point: {
    x: number;
    y: number;
  },
  origin: {
    x: number;
    y: number;
  },
  angle: number
) {
  const angleRad = (angle * Math.PI) / 180;
  return {
    x:
      Math.cos(angleRad) * (point.x - origin.x) -
      Math.sin(angleRad) * (point.y - origin.y) +
      origin.x,
    y:
      Math.sin(angleRad) * (point.x - origin.x) +
      Math.cos(angleRad) * (point.y - origin.y) +
      origin.y,
  };
}
