import * as Sentry from '@sentry/react';
import { track } from '@vercel/analytics';

const DISCORD_WEBHOOK_URL = import.meta.env.VITE_DISCORD_WEBHOOK_URL as string;

const callDiscordWebhook = async (message: string, error: Record<string, string>) => {
  await fetch(DISCORD_WEBHOOK_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      content: message,
      embeds: [
        {
          title: 'Erreur',
          description: JSON.stringify(error),
        },
      ],
    }),
  });
};

export const trackError = async (message: string, error: Record<string, string>) => {
  if (!import.meta.env.PROD) {
    console.error(error);
  }
  try {
    Sentry.captureException(message, error);
    track(message, error);
    await callDiscordWebhook(message, error);
  } catch (error) {
    console.error(error);
  }
};
