import { motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CarPortModel, ProductType } from '@/apis';
import { ArrowRight } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button, InfoCard } from '@/components/ui';
import { LocationMap, RidgeMap, RoofMap, ShelterMap } from '@/components/ui/Map';
import { type Simulation, useLocalProject } from '@/services/project';

// t('N')
// t('NE')
// t('E')
// t('SE')
// t('S')
// t('SO')
// t('O')
// t('NO')

// Return width and length of the shelter based on the model in meters
const shelterDimensionsByModel = (installationData?: Simulation) => {
  switch (installationData?.supportBuilding?.model) {
    case CarPortModel.SMALL:
      return { width: 3.87, length: 6.94 };
    case CarPortModel.MEDIUM:
      return { width: 5.81, length: 6.94 };
    case CarPortModel.LARGE:
      return { width: 5.81, length: 8.64 };
    default:
      return undefined;
  }
};

const LocationStep = ({
  onNext,
  onPrev,
  onGetCenter,
}: {
  onNext: () => void;
  onPrev: () => void;
  onGetCenter: (center: google.maps.LatLngLiteral) => void;
}) => {
  const { t } = useTranslation();
  const { installationData, mutateInstallationData } = useLocalProject();
  return (
    <>
      <AnimatedPage>
        <motion.h2
          initial={{ x: '100vw' }}
          animate={{ x: '0vw' }}
          className="text-sm font-medium text-black ">
          {t('location-step-title')}
        </motion.h2>
        <motion.h3
          initial={{ x: '100vw' }}
          animate={{ x: '0vw' }}
          className="text-sm font-bold text-secondary ">
          {t('location-step-subtitle')}
        </motion.h3>

        <LocationMap
          placeId={installationData?.address?.placeId ?? 'ChIJATr1n-Fx5kcRjQb6q6cdQDY'} // Default to Paris
          onGetCenter={onGetCenter}
          onCalculateTravel={(travelFromSoleriel) => {
            mutateInstallationData({
              address: {
                ...installationData?.address,
                travelFromSoleriel,
              } as Simulation['address'],
            });
          }}
        />
      </AnimatedPage>
      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={onPrev}
        />
        <Button
          label={t('next-step')}
          className={'mt-4 self-end bg-primary text-white'}
          onPress={onNext}
          PostIcon={(props) => <ArrowRight {...props} />}
        />
      </div>
    </>
  );
};

const RoofStep = ({
  center,
  onChange,
  onNext,
  onPrev,
}: {
  center: google.maps.LatLngLiteral;
  onChange: ({
    points,
    panelNbr,
    colNbr,
    lineNbr,
    area,
  }: {
    points: google.maps.LatLngLiteral[];
    panelNbr: number;
    colNbr: number;
    lineNbr: number;
    area: number;
  }) => void;
  onNext: () => void;
  onPrev: () => void;
}) => {
  const { t } = useTranslation();
  const [panelNbr, setPanelNbr] = useState<number>(0);

  return (
    <>
      <AnimatedPage>
        <motion.h2
          initial={{ x: '100vw' }}
          animate={{ x: '0vw' }}
          className="text-sm font-medium text-black">
          {t('roof-step-title')}
        </motion.h2>
        <motion.h3
          initial={{ x: '100vw' }}
          animate={{ x: '0vw' }}
          className="text-sm font-bold text-secondary ">
          {t('roof-step-subtitle')}
        </motion.h3>

        <RoofMap
          center={center}
          onChange={({ points, panelNbr, colNbr, lineNbr, area }) => {
            setPanelNbr(panelNbr);
            onChange({ points, panelNbr, colNbr, lineNbr, area });
          }}
        />
        <div className="self-start rounded-xl border border-grey2 bg-white p-4 text-left text-sm text-black shadow-sm">
          <p className="text-xs">{t('number-of-panels')}</p>
          <p className="text-base font-bold">{panelNbr}</p>
        </div>
      </AnimatedPage>
      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={onPrev}
        />
        <Button
          label={t('next-step')}
          className={'mt-4 self-end bg-primary text-white'}
          onPress={onNext}
          PostIcon={(props) => <ArrowRight {...props} />}
        />
      </div>
    </>
  );
};

const RidgeStep = ({
  roofPoints,
  panelNbr,
  onChange,
  onNext,
  onPrev,
}: {
  roofPoints: google.maps.LatLngLiteral[];
  panelNbr: number;
  onChange: (azimutDegree: number) => void;
  onNext: () => void;
  onPrev: () => void;
}) => {
  const { t } = useTranslation();
  const [azimuthCardinal, setAzimuthCardinal] = useState<string>();

  return (
    <>
      <AnimatedPage>
        <motion.h2
          initial={{ x: '100vw' }}
          animate={{ x: '0vw' }}
          className="text-sm font-medium text-secondary">
          {t('ridge-step-title')}
        </motion.h2>
        <motion.h3
          initial={{ x: '100vw' }}
          animate={{ x: '0vw' }}
          className="text-sm font-bold text-secondary ">
          {t('ridge-step-subtitle')}
        </motion.h3>

        <RidgeMap
          center={roofPoints[0]}
          roofPoints={roofPoints}
          onCalculateOrientation={(orientation) => {
            setAzimuthCardinal(orientation.azimuthCardinal);
            onChange(orientation.azimuth);
          }}
        />
        <div className="flex items-center gap-x-2">
          <div className="self-start rounded-xl border border-grey2 bg-white p-4 text-left text-sm text-black shadow-sm">
            <p className="text-xs">{t('number-of-panels')}</p>
            <p className="text-base font-bold">{panelNbr}</p>
          </div>
          <div className="self-start rounded-xl border border-grey2 bg-white p-4 text-left text-sm text-black shadow-sm">
            <p className="text-xs">{t('orientation')}</p>
            <p className="text-base font-bold">{t(azimuthCardinal ?? '')}</p>
          </div>
        </div>
      </AnimatedPage>
      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={onPrev}
        />
        <Button
          label={t('next-step')}
          className={'mt-4 self-end bg-primary text-white'}
          isDisabled={!azimuthCardinal}
          onPress={onNext}
          PostIcon={(props) => <ArrowRight {...props} />}
        />
      </div>
    </>
  );
};

const ShelterStep = ({
  center,
  onNext,
  onPrev,
}: {
  center: google.maps.LatLngLiteral;
  onNext: () => void;
  onPrev: () => void;
}) => {
  const { t } = useTranslation();
  const { installationData, mutateInstallationData } = useLocalProject();
  const [azimuthCardinal, setAzimuthCardinal] = useState<string>('S');

  return (
    <>
      <AnimatedPage>
        <motion.h2
          initial={{ x: '100vw' }}
          animate={{ x: '0vw' }}
          className="text-sm font-medium text-black">
          {t('shelter-step-title')}
        </motion.h2>
        <motion.h3
          initial={{ x: '100vw' }}
          animate={{ x: '0vw' }}
          className="text-sm font-bold text-secondary ">
          {t('shelter-step-subtitle')}
        </motion.h3>

        <ShelterMap
          center={center}
          shelterDimensions={shelterDimensionsByModel(installationData)}
          onCalculateOrientation={(orientation) => {
            setAzimuthCardinal(orientation.azimuthCardinal);
            mutateInstallationData({
              solarSurface: {
                ...installationData?.solarSurface,
                azimutDegree: orientation.azimuth,
              } as Simulation['solarSurface'],
            });
          }}
        />
        <div className="self-start rounded-xl border border-grey2 bg-white p-4 text-left text-sm text-black shadow-sm">
          <p className="text-xs">{t('orientation')}</p>
          <p className="text-base font-bold">{t(azimuthCardinal ?? '')}</p>
        </div>
      </AnimatedPage>
      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={onPrev}
        />
        <Button
          label={t('next-step')}
          isDisabled={!installationData?.solarSurface?.azimutDegree}
          className={'mt-4 self-end bg-primary text-white'}
          onPress={onNext}
          PostIcon={(props) => <ArrowRight {...props} />}
        />
      </div>
    </>
  );
};

const Map = ({ nextRoute, previousRoute }: { nextRoute: string; previousRoute: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { installationData, mutateInstallationData } = useLocalProject();
  const [step, setStep] = useState('3_1');
  const [roofPoints, setRoofPoints] = useState<google.maps.LatLngLiteral[]>([]);
  const hasShelter = installationData?.supportBuilding?.productType === ProductType.CAR_PORT;
  const defaultGeoloc = useMemo(
    () => ({
      lat: installationData?.address?.latitude ?? 0,
      lng: installationData?.address?.longitude ?? 0,
    }),
    [installationData?.address?.latitude, installationData?.address?.longitude]
  );

  return (
    <AnimatedPage>
      <h1 className="text-lg font-semibold text-black">{t('map-title')}</h1>
      {step === '3_1' ? (
        <LocationStep
          onNext={() => {
            if (hasShelter) {
              setStep('3_4');
            } else {
              setStep('3_2');
            }
          }}
          onPrev={() => {
            navigate(previousRoute);
          }}
          onGetCenter={(center) => {
            mutateInstallationData({
              address: {
                ...(installationData?.address ?? {}),
                latitude: center.lat,
                longitude: center.lng,
              } as Simulation['address'],
              solarSurface: {
                ...installationData?.solarSurface,
                latitude: center.lat,
                longitude: center.lng,
              } as Simulation['solarSurface'],
            });
          }}
        />
      ) : step === '3_2' ? (
        <RoofStep
          center={defaultGeoloc}
          onChange={({ points, area, panelNbr, colNbr, lineNbr }) => {
            mutateInstallationData({
              solarSurface: {
                ...installationData?.solarSurface,
                roofSurfaceM2: area,
                panelNbr,
                columnNbr: colNbr,
                lineNbr,
                azimutDegree: 180,
                inclinationDegree: 15,
              },
            });
            setRoofPoints(points);
          }}
          onNext={() => {
            setStep('3_3');
          }}
          onPrev={() => {
            setStep('3_1');
          }}
        />
      ) : step === '3_3' ? (
        <RidgeStep
          roofPoints={roofPoints}
          panelNbr={installationData?.solarSurface.panelNbr ?? 0}
          onChange={(azimutDegree) => {
            mutateInstallationData({
              solarSurface: {
                ...installationData?.solarSurface,
                azimutDegree,
                inclinationDegree: 45,
              } as Simulation['solarSurface'],
            });
          }}
          onNext={() => {
            navigate(nextRoute);
          }}
          onPrev={() => {
            setStep('3_2');
          }}
        />
      ) : step === '3_4' ? (
        <ShelterStep
          center={defaultGeoloc}
          onNext={() => {
            navigate(nextRoute);
          }}
          onPrev={() => {
            setStep('3_1');
          }}
        />
      ) : null}
      <InfoCard level="success" border={false}>
        <p className="text-sm font-medium text-black">
          <Trans
            i18nKey="need-help"
            components={[
              <span key="0" className="font-semibold text-black" />,
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                key="1"
                href="https://calendly.com/celine-giangrasso/rdv"
                target="_blank"
                className="underline"
                rel="noreferrer"
              />,
            ]}
          />
        </p>
      </InfoCard>
    </AnimatedPage>
  );
};
export default Map;
